import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {NextUIProvider} from "@nextui-org/react";

import { Index } from "./pages/index";
import { Test } from "./pages/test";

import { PWAProvider } from "./components/context/pwa_context";

function App() {
	const navigate = useNavigate();

	useEffect(() => {
        const root = document.documentElement;
        // Always apply these classes
        root.classList.add('dark', 'text-foreground', 'bg-background');

        // Cleanup function removes 'dark' but leaves others
        return () => {
            root.classList.remove('dark', 'text-foreground', 'bg-background');
        };
    }, []);

	return (
		<NextUIProvider navigate={navigate}>
			<PWAProvider>
				<Routes>
					<Route path='/' element={ <Index /> } />
					<Route path='/test' element={ <Test /> } />

					<Route path='*' element={<Index />} />
				</Routes>
			</PWAProvider>
		</NextUIProvider>
	);
}

export default App;
