import { getIsIOS } from "./check/is_ios";
import { getIsMobile } from "./check/is_mobile";
import { getIsPWA } from "./check/is_pwa";

export function InstallAppPrompt() {
    const isPWA = getIsPWA();
    const isMobile = getIsMobile();
    const isIOS = getIsIOS();

    if (isPWA) return <></>;

    return (
        <div>
            {!isMobile ? (
                <div>
                    <p>Please open this app on mobile.</p>
                </div>
            ) : (
                <div>
                    <p>Install this app on your homescreen</p>
                    <ol>
                        <li>Tap the share button</li>
                        <li>Tap Add to Home Screen</li>
                    </ol>
                </div>
            )}
        </div>
    );
}