import React, { useState, useEffect, useRef } from "react";

import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip} from "@nextui-org/react";

export function Index() {
    // useEffect(() => {
    // }, []);

    return (
        <>
            <div className="p-6 mx-auto max-w-5xl">
                <Link href="/test">Test</Link>
            </div>
        </>
    )
}

