import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// import { useAlert } from './alert_context';
import { getIsPWA } from '../pwa/check/is_pwa';
import { InstallAppPrompt } from '../pwa/install_app_prompt';

const PWAContext = createContext(null);

export const PWAProvider = ({ children }) => {
    const navigate = useNavigate();
    // const { showAlert } = useAlert();
    // showAlert(`Failed to switch to the chain: ${result.error.message}`, 'error', 'bottom');

    const [isPWA, setIsPWA] = useState(getIsPWA());

    useEffect(() => {
        setIsPWA(getIsPWA());
    }, []);

    if (!isPWA) {
        return <InstallAppPrompt />;
    }

    return (
        <PWAContext.Provider value={{ }}>
            {children}
        </PWAContext.Provider>
    );
};

export const usePWA = () => useContext(PWAContext);
