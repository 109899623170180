import React, { useState, useEffect, useRef } from "react";

import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip, Spinner} from "@nextui-org/react";
import {
    Listbox,
    ListboxItem,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
} from "@nextui-org/react";

export function Test() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false)

    const getEthPrice = () => {
        setLoading(true)

        fetch(`${window.origin}/api/get_eth_price`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((data) => {
            console.log(data)
            setData(data.data)
            setLoading(false)
        })
        .catch((error) => {
            console.log("Fetch error: ", error);
            setLoading(false)
        });
    }

    // useEffect(() => {
    //     getEthPrice()
    // }, []);

    return (
        <>
            <div className="p-6 mx-auto max-w-5xl">
                {/* <Dropdown>
                    <DropdownTrigger>
                        <Button>Open</Button>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Navigation">
                        <DropdownItem key="home" href="/">Home</DropdownItem>
                        <DropdownItem key="about" href="/test">test</DropdownItem>
                    </DropdownMenu>
                </Dropdown> */}
                <Link href="/">home</Link>

                <Card>
                    <CardBody>
                        {loading ? (
                            <Spinner size="sm" />
                        ) : (
                            data ? <span>eth price: {data}</span> : null
                        )}
                    </CardBody>
                    <CardFooter>
                        <Button size="sm" onClick={getEthPrice}>get eth price</Button>
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}

